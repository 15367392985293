import { useWizard } from 'react-use-wizard';
import DataContext from '../../Context/DataProvider';
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { Transition } from '@headlessui/react'

export default function Container({ children }) {
    const { handleStep, previousStep, nextStep, activeStep } = useWizard();
    const { t, i18n } = useTranslation();
    const { answerList, setAnswerList, errAnswer, setErrAnswer, errMessage } = useContext(DataContext);
    const question = 'Question' + activeStep
    const [headerShow, setHeaderShow] = useState(false)
    const [bodyShow, setBodyShow] = useState(false)
    const [errShow, setErrShow] = useState(false)

    const GoToNext = () => {
        let valid = true
        const found = answerList.find(obj => {
            return obj.key === question
        })
        if (!found?.answer) {
            valid = false
            setErrAnswer(errMessage)
        }

        if (valid) {
            setErrAnswer('')
            nextStep();
        }
    }

    useEffect(() => {
        setHeaderShow(true)
    }, [])


    useEffect(() => {
        setBodyShow(true)
    }, [headerShow])

    useEffect(() => {
        errAnswer && setErrShow(true)
    }, [errAnswer])

    return (
        <div className="fixed w-full">
            <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none  bg-gray-light">
                <div className="relative flex-grow w-auto mx-auto max-w-4xl mt-[100px] lg:mt-[200px] bg-gray-light border-solid border-slate-200 rounded-t">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-center w-full p-5 rounded-t border-b border-gray ">
                            <Transition
                                className="px-2"
                                show={bodyShow}
                                enter="transition-all ease-in-out duration-500 delay-[200ms]"
                                enterFrom="opacity-0 translate-x-6"
                                enterTo="opacity-100 translate-x-0"
                                leave="transition-all ease-in-out duration-300"
                                leaveFrom="opacity-100 translate-x-6"
                                leaveTo="opacity-0 translate-x-0">
                                <h3 className="flex-grow text-2xl font-semibold">
                                    Question {activeStep}
                                </h3>
                            </Transition>
                            <div className='flex-grow'>
                                <Transition
                                    className="w-full"
                                    show={errShow}
                                    enter="transition-all ease-in-out duration-500 delay-[200ms]"
                                    enterFrom="opacity-0 translate-x-6"
                                    enterTo="opacity-100 translate-x-0"
                                    leave="transition-all ease-in-out duration-300"
                                    leaveFrom="opacity-100 translate-x-6"
                                    leaveTo="opacity-0 translate-x-0"
                                >
                                    <span className="flex-grow px-8 italic text-sm text-red">{errAnswer}</span>
                                </Transition>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                            </svg>
                        </div>
                        {/*body*/}
                        <div className="relative flex-auto min-h-[300px]">
                            <Transition
                                className=""
                                show={bodyShow}

                                enter="transition-all ease-in-out duration-500 delay-[400ms]"
                                enterFrom="opacity-0 translate-y-6"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition-all ease-in-out duration-300"
                                leaveFrom="opacity-100 translate-x-6"
                                leaveTo="opacity-0 translate-x-0"
                            >
                                {children}
                            </Transition>
                        </div >
                        {/*footer*/}
                        <div className="flex items-center border-t border-gray mx-5 py-3">
                            <div className='flex-grow'>
                                <button className='bg-gray-light text-gray-dark p-2 px-5 m-1 rounded' onClick={() => { setErrAnswer(''); previousStep() }}>{t("general.previous")}</button>
                            </div>
                            <div className=''>
                                <button className='bg-red text-white p-2 px-5 m-1 rounded' onClick={() => GoToNext()}>{t("general.next")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}