import { useEffect, useState, useContext } from 'react';
import DataContext from '../../Context/DataProvider';
import { useTranslation } from "react-i18next";
import Container from './Container';

export default function Question1() {
    const { answerList, setAnswerList, setErrAnswer } = useContext(DataContext);
    const { t, i18n } = useTranslation();
    const [selected, setSelected] = useState('');
    const question = 'Question1';
    const desc = t("question.question1.q")

    const handleChange = e => {
        setSelected(e.target.value)
        setErrAnswer('')
    };

    useEffect(() => {
        const found = answerList.find(obj => {
            return obj.key === question
        })
        if (found === null || found === undefined) {
            answerList.push({
                key: question,
                desc: desc,
                answer: selected,
            });
        }
        else {
            const found = answerList.map((obj) => {
                if (obj.key === question)
                    return { ...obj, answer: selected }
                return { ...obj }
            })
            setAnswerList(found)
        }
    }, [selected])

    useEffect(() => {
        const found = answerList.find(obj => {
            return obj.key === question
        })

        if (found != null || found != undefined)
            setSelected(found.answer);
    }, [])

    return (

        <Container>
            <div className="flex-grow px-5 bg-[#fff]] m-0 mt-4 mb-10 ml-2 font-bold text-[18px]">
                {desc}
            </div>
            <div className='flex flex-col w-full'>
                <div className='flex-1 flex items-center p-1 px-10'>
                    <input className='w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 focus:outline-none' id='1' name='answer' type={'radio'} value={t("question.question1.answer1")} checked={selected === t("question.question1.answer1")} onChange={handleChange} />
                    <label className='p-1 px-3' htmlFor='1'>{t("question.question1.answer1")}</label>
                </div>
                <div className='flex-1 flex items-center p-1 px-10'>
                    <input className='w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 focus:outline-none'
                        id='2'
                        name='answer'
                        type={'radio'}
                        value={t("question.question1.answer2")}
                        checked={selected === t("question.question1.answer2")}
                        onChange={handleChange}
                    />
                    <label className='p-1 px-3' htmlFor='2'>{t("question.question1.answer2")}</label>
                </div>
                <div className='flex-1 flex items-center p-1 px-10'>
                    <input className='w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 focus:outline-none'
                        id='3'
                        name='answer'
                        type={'radio'}
                        value={t("question.question1.answer3")}
                        checked={selected === t("question.question1.answer3")}
                        onChange={handleChange}
                    />
                    <label className='p-1 px-3' htmlFor='3'>{t("question.question1.answer3")}</label>
                </div>
                <div className='flex-1 flex items-center p-1 px-10'>
                    <input className='w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 focus:outline-none'
                        id='4'
                        name='answer'
                        type={'radio'}
                        value={t("question.question1.answer4")}
                        checked={selected === t("question.question1.answer4")}
                        onChange={handleChange}
                    />
                    <label className='p-1 px-3' htmlFor='4'>{t("question.question1.answer4")}</label>
                </div>
            </div>
        </Container>
    )
}