import moment from "moment";
import { CheckListIco, backImage } from '../../Assets/Icons'
import { useRef, useState } from "react";
import Details from "./Details";
import { useOutsideFinder } from '../useOutsideFinder'

export default function Card({ data }) {
    const { surId, surCreatedDate, surdAnswer } = data
    const [show, setShow] = useState(false)
    const cardRef = useRef()

    useOutsideFinder(() => setShow(false), cardRef)

    return (
        <div className="p-3">
            <div className={"flex flex-col w-56 h-24 p-2 shadow-md bg-gray-light rounde-md hover:bg-blue cursor-pointer relative " + (show && 'bg-blue')}
                onClick={() => setShow(!show)}>
                <CheckListIco className='' />
                <div className="flex-1 text-sm">
                    <span className="text-sm">Survey #</span>
                    <span>{surId}</span>
                </div>
                <div className="flex-1 flex">
                    <span className="px-2 font-semibold">{surdAnswer}</span>
                </div>
                <div className="flex-1 flex justify-end items-end text-sm italic">
                    <span>{moment(surCreatedDate).format('YYYY-MM-DD')}</span>
                </div>
            </div>

            {
                show
                    ? <div ref={cardRef}><Details surveyId={surId} secret={''} ref={cardRef} /></div>
                    : null
            }
        </div>
    )
}