import './App.css';
import Main from './Main';
import Survey from './Components/Layout/Survey';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import List from './Components/Admin/List';
import { DataProvider } from './Context/DataProvider';

function App() {
  return (
    <DataProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/Survey" element={<Survey />} />
          <Route exact path="/Admin" element={<List />} />
          <Route exact path="/Admin" element={<List />} />
        </Routes>
      </Router>
    </DataProvider>

  );
}

export default App;
