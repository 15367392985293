import { createContext, useEffect, useReducer, useState } from "react";

const DataContext = createContext({})
export const DataProvider = ({ children }) => {
    const [answerList, setAnswerList] = useState([])
    const [errAnswer, setErrAnswer] = useState('')
    const errMessage = 'Oops! You forgot to answer this question.'

    return (
        <DataContext.Provider value={{
            answerList, setAnswerList,
            errAnswer, setErrAnswer,
            errMessage
        }}>
            {children}
        </DataContext.Provider >
    )
};

export default DataContext