import { useContext, useState, useEffect } from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import DataContext from '../../Context/DataProvider';
import { useTranslation } from "react-i18next";
import Container from './Container';

export default function Question9() {
    const { t, i18n } = useTranslation();
    const question = 'Question9';
    const desc = t("question.question9")
    const { handleStep, previousStep, nextStep } = useWizard();
    const { answerList, setAnswerList, setErrAnswer } = useContext(DataContext);
    const found = answerList.find(obj => {
        return obj.key === question
    })
    const [selected, setSelected] = useState('');

    const handleChange = e => {
        setSelected(e.target.value)
        setErrAnswer('')
    };

    useEffect(() => {
        const found = answerList.find(obj => {
            return obj.key === question
        })
        if (found === null || found === undefined) {
            answerList.push({
                key: question,
                desc: desc,
                answer: selected,
            });
        }
        else {
            const found = answerList.map((obj) => {
                if (obj.key === question)
                    return { ...obj, answer: selected }
                return { ...obj }
            })
            setAnswerList(found)
        }
    }, [selected])

    useEffect(() => {
        const found = answerList.find(obj => {
            return obj.key === question
        })

        if (found != null || found != undefined)
            setSelected(found.answer);
    }, [])

    return (
        <Container>
            <div className="relative flex-auto min-h-[300px]">
                <div className="flex-grow px-5 bg-[#fff]] m-0 mt-4 mb-10 ml-2 font-bold text-[18px]">
                    {desc}
                </div>
                <div className='flex-1 p-2 px-10'>
                    <textarea className='w-full border border-gray outline-none p-2' rows={5} maxLength={255} value={selected} onChange={(e) => { setErrAnswer(''); setSelected(e.target.value) }} />
                </div>
            </div >
        </Container>
    );
}