import { useContext, useState, useEffect } from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import DataContext from '../../Context/DataProvider';
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";
import Container from './Container';

export default function Question10() {
    const { t, i18n } = useTranslation();
    const question = 'Question10';
    const desc = t("question.question10")
    const { handleStep, previousStep, nextStep } = useWizard();
    const { answerList, setAnswerList, setErrAnswer } = useContext(DataContext);
    const found = answerList.find(obj => {
        return obj.key === question
    })
    const [selected, setSelected] = useState(found ? found.answer : 0);

    const ratingChanged = (newRating) => {
        setErrAnswer('')
        setSelected(newRating)
    };

    useEffect(() => {
        const found = answerList.find(obj => {
            return obj.key === question
        })
        if (found === null || found === undefined) {
            answerList.push({
                key: question,
                desc: desc,
                answer: selected,
            });
        }
        else {
            const found = answerList.map((obj) => {
                if (obj.key === question)
                    return { ...obj, answer: selected }
                return { ...obj }
            })
            setAnswerList(found)
        }
    }, [selected])

    useEffect(() => {
        const found = answerList.find(obj => {
            return obj.key === question
        })

        if (found !== null || found !== undefined || found !== 1)
            setSelected(found.answer);
    }, [])

    return (
        <Container>
            <div className="relative flex-auto min-h-[300px]">
                <div className="flex-grow px-5 bg-[#fff]] m-0 mt-4 mb-10 ml-2 font-bold text-[18px]">
                    {desc}
                </div>
                <div className='flex flex-col w-full inline-block'>
                    <div className='flex-1 p-2 px-10'>
                        <ReactStars
                            count={10}
                            onChange={ratingChanged}
                            size={48}
                            activeColor="#ffd700"
                            color={''}
                            value={parseInt(selected)}
                        />
                    </div>
                </div>
            </div >
        </Container>
    );
}