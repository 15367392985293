import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios';
import { useEffect, useState, useRef, Children } from "react";
import logo from '../../Assets/img/auto-logo.jpg'
import moment from 'moment';
import Header from '../Layout/Header';
import Details from "./Details";
import { useDownloadExcel } from 'react-export-table-to-excel';
import Card from "./Card";

export default function List() {
    const base_url = process.env.REACT_APP_BASEURL
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const secret = searchParams.get("k")
    const [list, setList] = useState([])
    const tableRef = useRef(null);
    const [exportData, setExportData] = useState([])
    const TOKEN = process.env.REACT_APP_SECRET

    const config = { headers: { authorization: `bear ${TOKEN}` } }

    const getSurveyList = () => {
        axios.post(base_url + "surveyList/", { secret: TOKEN }, config)
            .then((response) => {
                if (response.status == 200) {
                    const { status, surveys } = response.data
                    if (status)
                        setList(surveys)
                    else
                        console.log(status)
                }
            });
    }

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Client Survey',
        sheet: 'survey'
    })

    useEffect(() => {
        if (secret != null & secret === process.env.REACT_APP_SECRET) {
            getSurveyList()
        }
        else
            navigate('/')
    }, [])

    return (
        <div className="flex flex-col w-full min-h-screen bg-gray-light">
            <Header />
            <div className="flex w-full items-center justify-center mt-[100px]">
            </div>
            <div className="mx-5">
                <div className="w-full shadow bg-white px-10 overflow-y-auto pb-10">
                    <div className="flex flex-row">
                        <h3 className="font-bold block text-[18px] p-3 text-gray-dark flex-grow">List of Survey</h3>
                        <button type="button" onClick={onDownload} className="p-2 justify-center vertical-center bg-green text-white m-1 text-[16px] min-w-[75px] rounded">Export</button>
                    </div>

                    <div className="flex flex-row flex-wrap justify-center">

                        {
                            list
                                ? list.map((x, index) => {
                                    return <Card key={x.surId} data={x} />
                                })
                                : null
                        }

                    </div>
                    <table className="hidden" ref={tableRef}>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Comapny Name</th>
                                <th>Crated Date</th>
                                <th>Q1</th>
                                <th>Q2</th>
                                <th>Q3</th>
                                <th>Q4</th>
                                <th>Q5</th>
                                <th>Q6</th>
                                <th>Q7</th>
                                <th>Q8</th>
                                <th>Q9</th>
                                <th>Q10</th>
                                <th>Q11</th>
                                <th>Q12</th>
                                <th>Q13</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                exportData
                                    ? exportData.map((x, index) => {
                                        return <tr key={index}>
                                            <td>{x.Id}</td>
                                            <td>{x.Name}</td>
                                            <td>{x.Company}</td>
                                            <td>{x.Date}</td>
                                            <td>{x.Q1}</td>
                                            <td>{x.Q2}</td>
                                            <td>{x.Q3}</td>
                                            <td>{x.Q4}</td>
                                            <td>{x.Q5}</td>
                                            <td>{x.Q6}</td>
                                            <td>{x.Q7}</td>
                                            <td>{x.Q8}</td>
                                            <td>{x.Q9}</td>
                                            <td>{x.Q10}</td>
                                            <td>{x.Q11}</td>
                                            <td>{x.Q12}</td>
                                            <td>{x.Q13}</td>
                                        </tr>
                                    })
                                    : null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}