import { useContext, useState } from 'react';
import DataContext from '../../Context/DataProvider';
import { useWizard } from 'react-use-wizard';
import axios from 'axios';
import { useTranslation } from "react-i18next";

export default function Question13() {
    const { t, i18n } = useTranslation();
    const { previousStep, goToStep } = useWizard();
    const { answerList, setAnswerList } = useContext(DataContext);
    const [disableSubmit, setDisableSubmit] = useState(false)
    const BASEURL = process.env.REACT_APP_BASEURL;
    const TOKEN = process.env.REACT_APP_SECRET;

    const clearState = () => {
        setAnswerList([])
    }

    function makeSecret(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const submitSurvey = () => {
        try {
            setDisableSubmit(true)
            axios.post(BASEURL + "survey/add",
                {
                    answerList,
                    secret: makeSecret(50)
                }, { headers: { authorization: `bear ${TOKEN}` } })
                .then((response) => {
                    if (response.status == 200) {
                        const { status, data } = response
                        if (status > 0) {
                            if (data.status) {
                                clearState();
                                goToStep(0);
                            }
                        }
                    }
                    setDisableSubmit(false)
                });
        } catch (err) {
            console.log(err)
            setDisableSubmit(false)
        }
    }

    return (
        <div className="fixed w-full">
            <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none  bg-gray-light">
                <div className="relative flex-grow w-auto mx-auto max-w-4xl mt-[100px] lg:mt-[200px] bg-gray-light border-solid border-slate-200 rounded-t">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex p-5 rounded-t border-b border-gray mx-5">
                            <h3 className="text-2xl font-semibold w-full">
                                {t("submit.youranswer")}
                            </h3>
                        </div>
                        {/*body*/}
                        <div className="relative flex-auto min-h-[300px] max-h-[300px] overflow-y-auto">
                            {answerList
                                ? answerList.map((x, index) => {
                                    return (
                                        <div key={index} className='flex flex-col mx-10 py-2 border-b border-gray-light'>
                                            <div className=''><span className='font-bold'>{x.key}:</span> {x.desc}</div>
                                            <div className='mx-5'>{x.answer}</div>
                                        </div>
                                    )
                                })
                                : null
                            }
                        </div >
                        {/*footer*/}
                        < div className="flex items-center border-t border-gray mx-5 py-3">
                            <div className='flex-grow'>
                                <button className='bg-gray text-gray-dark p-2 px-5 m-1 rounded' onClick={() => previousStep()}>{t("general.previous")}</button>
                            </div>
                            <div className=''>
                                <button disabled={disableSubmit} className='bg-blue p-2 px-5 m-1 rounded disabled:opacity-60' onClick={() => submitSurvey()}>{t("submit.submit")}</button>
                            </div>
                        </div >
                    </div >
                </div >
            </div >
        </div>
    );
}