
import { Wizard, useWizard } from 'react-use-wizard';
import ReactStars from "react-rating-stars-component";
import Question1 from './Components/Questions/Question1';
import Question2 from './Components/Questions/Question2';
import Question3 from './Components/Questions/Question3';
import Question4 from './Components/Questions/Question4';
import Question5 from './Components/Questions/Question5';
import Question6 from './Components/Questions/Question6';
import Question7 from './Components/Questions/Question7';
import Question8 from './Components/Questions/Question8';
import Question9 from './Components/Questions/Question9';
import Question10 from './Components/Questions/Question10';
import Question11 from './Components/Questions/Question11';
import Submit from './Components/Questions/Submit';
import Header from './Components/Layout/Header';
import Footer from './Components/Layout/Footer';
import Language from './Components/Questions/Language';
import Location from './Components/Questions/Location';
export default function Main() {

    return (
        <div>
            <Header />
            <Wizard>
                <Language />
                <Question1 />
                <Question2 />
                <Question3 />
                <Question4 />
                <Question5 />
                <Question6 />
                <Question7 />
                <Question8 />
                <Question9 />
                <Question10 />
                <Question11 />
                <Location />
                <Submit />
            </Wizard>
            <Footer />
        </div>
    )
}