import { useEffect, useRef, useState } from "react";
import axios from 'axios';

export default function Details({ surveyId }) {
    const [list, setList] = useState([])
    const base_url = process.env.REACT_APP_BASEURL
    const TOKEN = process.env.REACT_APP_SECRET
    const config = { headers: { authorization: `bear ${TOKEN}` } }
    const cardRef = useRef()

    const getDetails = () => {
        if (surveyId != null) {
            axios.post(base_url + "surveyList/details",
                {
                    id: surveyId,
                    secret: TOKEN
                }, config)
                .then((response) => {
                    const { status, data } = response.data
                    console.log(data)
                    if (status) {
                        setList(data)
                    }
                    else
                        console.log(response);
                });
        }
    }

    useEffect(() => {
        getDetails()
    }, [])

    return (
        <div className="absolute bg-white p-5 z-[20] flex flex-col shadow">
            {
                list.length > 0
                    ? <div className="mt-2 text-center sm:ml-4 sm:text-left w-full mb-4">
                        <div className="w-full max-h-[300px] overflow-y-auto">
                            <table className="w-full">
                                <thead>
                                    <tr className="border-b border-gray-light p-3">
                                        <th className="p-2">#</th>
                                        <th className="p-2 hidden">Question</th>
                                        <th className="p-2">Title</th>
                                        <th className="p-2">Answer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map((x, index) => {
                                            return <tr key={index}>
                                                <td className="p-2 border-b border-gray-light">{index + 1}</td>
                                                <td className="p-2 border-b border-gray-light hidden">{x.surdQuestion}</td>
                                                <td className="p-2 border-b border-gray-light">{x.surdDesc}</td>
                                                <td className="p-2 border-b border-gray-light">{x.surdAnswer}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : <div className="flex flex-row items-center mx-auto bg-red-100 rounded-full">
                        <span>Loading Survey Answers</span>
                    </div>
            }
        </div>
    );
}